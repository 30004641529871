jQuery(document).ready(function($) {
	//*** Mega menu ****//
	$(".mobile-navigation-menu").mmenu({
		offCanvas: {
			position: "right"
		},
		navbar: {
			add: true,
			title: ""
		}
	});

	$(".nav-menu-icon").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	//*** Apply FitVids to YouTube videos ***//
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-body").fitVids({ ignore: ".nofit" });
		$(".homebox").fitVids({ ignore: ".nofit" });
	}

	/*** Dropdown menu ***/
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .dropdown-toggle > .dropdown-text").click(function(event) {
			if ( $(this).parent().siblings(".dropdown-list").is(":visible") ) {
				$(".dropdown-list").removeClass("w--open");
				event.preventDefault();
				$(this).parent().siblings(".dropdown-list").addClass("w--open");
			}
		});
	}
	else {
		$(".has-dropdown").hover(function() {
			$(this).find(".dropdown-list").addClass("w--open");
		}, function() {
			$(this).find(".dropdown-list").removeClass("w--open");
		});
	}

	//*** Homepage ***//
	$(".cb-static-title a").parent().addClass("no-padding");
	$(".cb-title-link a").parent().addClass("no-padding");

	//*** Webflow: Interactions: Init ***//
	Webflow.require('ix').init([
		{slug: "fade-in-bottom-page-loads",name: "Fade in bottom (page loads)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-left-scroll-in",name: "Fade in left (scroll in)",value: {style: {opacity: 0,x: "-50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-right-scroll-in",name: "Fade in right (scroll in)",value: {style: {opacity: 0,x: "50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-top-scroll-in",name: "Fade in top (scroll in)",value: {style: {opacity: 0,x: "0px",y: "-50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-bottom-scroll-in",name: "Fade in bottom (scroll in)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "bounce-in-scroll-in",name: "Bounce in (scroll in)",value: {style: {opacity: 0,scaleX: 0.6000000000000006,scaleY: 0.6000000000000006,scaleZ: 1},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 600ms ease 0ms, opacity 600ms ease 0ms",scaleX: 1.08,scaleY: 1.08,scaleZ: 1},{transition: "transform 150ms ease-out-cubic 0ms",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "scale-on-scroll",name: "Scale on Scroll",value: {style: {opacity: 0,scaleX: 0.01,scaleY: 0.01,scaleZ: 1},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 600ms ease 0ms, opacity 600ms ease 0ms",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "new-interaction",name: "New Interaction",value: {style: {},triggers: []}}
	]);
});

! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
